import { WButton } from "../../components/button/WButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";

export function SubInterfaceModal({ vlanTag, macAddr, ipAddr }: { vlanTag: number; macAddr: string; ipAddr: string }) {
    return (
        <WModal
            button={
                <WButton
                    color="primary"
                    size="xs"
                    variant="ghost"
                    iconSide="right"
                    icon="jp-information-icon"
                    action={undefined}
                >
                    VLAN
                </WButton>
            }
        >
            <WModalContent title="Sub-Interface Configuration Example" modalAction={undefined}>
                <p className="color-muted pb-2">
                    Below is an example of how to configure this sub-interface on a Linux server.
                </p>

                <code>
                    ip link add link ens3 name vlan{vlanTag} address {macAddr} type vlan id {vlanTag}
                    <br />
                    ip link set dev vlan{vlanTag} up
                    <br />
                    ip addr add {ipAddr}/24 dev vlan{vlanTag}
                    <br />
                </code>
            </WModalContent>
        </WModal>
    );
}
