import { useState } from "react";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { DeleteResourceModalContent } from "../../components/modal/DeleteResourceModal.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { deleteIpAddressMutation, ipQueryAtom } from "../network/ipAddress/apiOperations.ts";
import type { DeleteVmMutation } from "./VmView.tsx";
import type { VirtualMachineLoc } from "./vmQuery.ts";

export interface VmDeleteModalProps {
    vm: VirtualMachineLoc;
    deleteVmMutation: DeleteVmMutation;
}

export function VmDeleteModal({ vm, deleteVmMutation }: VmDeleteModalProps) {
    //#region Hooks
    const delIpMutation = useStandardMutation(deleteIpAddressMutation);

    const ips = useSuspenseQueryAtom(ipQueryAtom(vm.location));
    const attachedPublicIp = [...ips.values()].find(
        (ip) => ip?.assigned_to_resource_type === "virtual_machine" && ip.assigned_to === vm.uuid,
    );

    const [deleteFloatingIp, setDeleteFloatingIp] = useState<string>("");
    //#endregion

    const { name } = vm;
    const deleteAlsoIp = deleteFloatingIp === "yes" && attachedPublicIp;

    async function deleteAction() {
        await deleteVmMutation.mutateAsync({ uuid: vm.uuid, location: vm.location });
        if (deleteAlsoIp) {
            await delIpMutation.mutateAsync({ location: vm.location, address: attachedPublicIp.address });
        }
    }

    return (
        <DeleteModal
            title="Delete Resource"
            modalAction={deleteAction}
            isActionDisabled={attachedPublicIp && deleteFloatingIp === ""}
        >
            <DeleteResourceModalContent
                ipAddress={attachedPublicIp}
                value={deleteFloatingIp}
                onChange={(value: string) => setDeleteFloatingIp(value)}
            >
                <p className="pb-3">
                    Deleting a resource "{name}" will permanently remove it and will not be accessible after that.
                </p>
                <p>
                    Deleting a resource will also delete your backups and snapshots and the machine cannot be restored
                    after that. If you wish to keep the backups and snapshots consider stopping the machine instead.
                </p>
            </DeleteResourceModalContent>
        </DeleteModal>
    );
}
