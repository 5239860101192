import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import { useState } from "react";
import { Input } from "react-aria-components";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { cn } from "../../utils/classNames.ts";
import { useGenerateUuid } from "../../utils/useGenerateUuid.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { vmCloneMutation, type VirtualMachineLoc } from "./vmQuery.ts";

function VmCloneModalContent({ vm }: { vm: VirtualMachineLoc }) {
    const { name: originalVmName, uuid, location } = vm;

    //#region Hooks
    const cloneMutation = useStandardMutation(vmCloneMutation);
    const requestUuid = useGenerateUuid(undefined);

    const initialName = `${originalVmName}-clone`;
    const [name, setName] = useState(initialName);

    //#endregion

    return (
        <WModalContent
            title="Clone Resource"
            label="Clone"
            modalAction={async () => {
                await cloneMutation.mutateAsync({
                    location,
                    requestUuid,
                    body: {
                        uuid,
                        name,
                    },
                });
                setName(initialName);
            }}
        >
            <WTextField autoFocus isRequired={true} label="Resource name" value={name} onChange={setName}>
                <Input style={{ width: "240px" }} className={cn(FF.FormFieldInput, TF.Input)} />
            </WTextField>
        </WModalContent>
    );
}

export function VmCloneModal({ vm }: { vm: VirtualMachineLoc }) {
    return (
        <WModal button={<WModalButton label="Clone" icon="jp-duplicate-icon" />}>
            <VmCloneModalContent vm={vm} />
        </WModal>
    );
}
