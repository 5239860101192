import TF from "../../components/forms/TextField.module.css";

import type { ReactNode } from "react";
import { useState } from "react";
import { FieldError, Input, NumberField } from "react-aria-components";
import { FormField } from "../../components/forms/FormField.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { cn } from "../../utils/classNames.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { VpcNetworkSelect } from "../network/VpcNetworkSelect.tsx";
import { addSubInterfaceMutation } from "./vmInterfacesQuery.ts";

interface SubInterfaceType {
    uuid: string;
    vmiUuid: string;
    networkUuid: string;
    location: string;
}

interface AddSubInterfaceType extends SubInterfaceType {
    button: ReactNode;
}

export function AddSubInterfaceButton({ uuid, vmiUuid, networkUuid, location, button }: AddSubInterfaceType) {
    return (
        <WModal button={button}>
            <AddSubInterfaceModalContent location={location} uuid={uuid} vmiUuid={vmiUuid} networkUuid={networkUuid} />
        </WModal>
    );
}

function AddSubInterfaceModalContent({ uuid, vmiUuid, networkUuid, location }: SubInterfaceType) {
    //#region Hooks
    const createMutation = useStandardMutation(addSubInterfaceMutation);

    const [vlan, setVlan] = useState<number>(NaN);
    const [vpc, setVpc] = useState<string>(networkUuid);
    //#endregion

    return (
        <WModalContent
            title="Add Sub-Interface"
            label="Add"
            modalAction={async () => {
                await createMutation.mutateAsync({
                    location,
                    uuid: uuid,
                    ifUuid: vmiUuid,
                    networkUuid: vpc,
                    vlanTag: vlan,
                });
            }}
        >
            <FormField isRequired label="VLAN ID" description="Enter a value between 1 and 4094">
                <NumberField
                    value={vlan}
                    onChange={(value) => setVlan(value)}
                    className={cn(TF.NumberField)}
                    minValue={1}
                    maxValue={4094}
                    aria-label="Set vlan id"
                    isRequired
                    formatOptions={{
                        maximumFractionDigits: 0,
                        signDisplay: "never",
                    }}
                >
                    <div>
                        <Input className={TF.Input} />
                    </div>
                    <FieldError />
                </NumberField>
            </FormField>

            <FormField isRequired wide label="VPC network">
                <VpcNetworkSelect
                    location={location}
                    isRequired
                    valueKey={vpc ?? null}
                    onChange={(value, key) => setVpc(key)}
                />
            </FormField>
        </WModalContent>
    );
}
