import type { QueryClient } from "@tanstack/query-core";
import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { VmInterface } from "@warrenio/api-spec/spec.oats.gen";
import { atomFamilyMulti } from "../../utils/jotai/atomFamilyMulti.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import { raiseRequestToast } from "../notifications/toast.tsx";
import { toastOptions } from "./vmQuery.ts";

export interface Params {
    location: string;
    uuid: string;
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null
        ? ["network/virtual-machines/interfaces"]
        : ["network/virtual-machines/interfaces", params.uuid, params.location];
}

export function getQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<VmInterface[]> => {
            const { location, uuid } = params;
            return getResponseData(
                await apiClient.GET("/{location}/network/virtual-machines/{uuid}/interfaces", {
                    signal,
                    params: { path: { location: location, uuid: uuid } },
                }),
            );
        },
    });
}

export const allInterfacesQueryAtom = atomFamilyMulti((location: string, uuid: string) =>
    atomFromStandardQueryOptions(getQuery, { location, uuid }),
);

export function addSubInterfaceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["network/virtual-machines/subinterfaces"],
        async mutationFn({
            location,
            uuid,
            ifUuid,
            networkUuid,
            vlanTag,
        }: {
            location: string;
            uuid: string;
            ifUuid: string;
            networkUuid: string;
            vlanTag: number;
        }) {
            return getResponseData(
                await api.POST("/{location}/network/virtual-machines/{uuid}/interfaces/{if_uuid}/subinterfaces", {
                    params: {
                        path: { location: location, uuid: uuid, if_uuid: ifUuid },
                        query: { network_uuid: networkUuid, vlan_tag: String(vlanTag) },
                    },
                }),
            );
        },
        async onSettled(_res, err, { location, uuid }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Sub-interface created",
                error: "Error creating sub-interface",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location, uuid }) });
        },
    });
}

export function deleteSubInterfaceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["network/virtual-machines/subinterfaces"],
        async mutationFn({ location, uuid, ifUuid }: { location: string; uuid: string; ifUuid: string }) {
            return getResponseData(
                await api.DELETE("/{location}/network/virtual-machines/{uuid}/subinterfaces/{if_uuid}", {
                    params: { path: { location: location, uuid: uuid, if_uuid: ifUuid } },
                }),
            );
        },
        async onSettled(_res, err, { location, uuid }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Sub-interface deleted",
                error: "Error deleting sub-interface",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location, uuid }) });
        },
    });
}
