import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { deleteSubInterfaceMutation } from "./vmInterfacesQuery.ts";

export function SubInterfaceDeleteModal({
    uuid,
    ifUuid,
    location,
}: {
    uuid: string;
    ifUuid: string;
    location: string;
}) {
    const deleteMutation = useStandardMutation(deleteSubInterfaceMutation);

    return (
        <DeleteModal
            inTable
            title="Delete Sub-interface"
            modalAction={async () => {
                await deleteMutation.mutateAsync({
                    location: location,
                    uuid: uuid,
                    ifUuid: ifUuid,
                });
            }}
        >
            Are you sure you want to delete sub-interface with uuid {ifUuid}?
        </DeleteModal>
    );
}
