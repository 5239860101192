import type { VmCreateBody } from "@warrenio/api-spec/spec.oats.gen";
import { useForm, type SubmitHandler } from "react-hook-form";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WHookForm } from "../../components/forms/WHookForm.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { ModalHeaderBlock } from "./ModalHeaderBlock.tsx";
import { ParamsTextField } from "./ParamsTextField.tsx";
import { osIsWindows } from "./os/os.ts";
import { parametersByNameAtom } from "./vmParametersQuery.ts";
import { changeVmUserPasswordMutation, startVmMutation, type VirtualMachineLoc } from "./vmQuery.ts";

export interface VmChangePasswordInputs extends Pick<VmCreateBody, "username" | "password"> {
    password_confirm: string;
}

function ChangePasswordModalContent({ vm }: { vm: VirtualMachineLoc }) {
    const { uuid, status, location, os_name, username } = vm;
    const isWindows = osIsWindows(os_name);

    //#region Hooks
    const params = useSuspenseQueryAtom(parametersByNameAtom);
    const startMutation = useStandardMutation(startVmMutation);

    const changePasswordMutation = useStandardMutation(changeVmUserPasswordMutation);

    //#endregion

    const usernameParams = params.get("username")!;
    const passwordParams = params.get("password")!;

    const onSubmit: SubmitHandler<VmChangePasswordInputs> = async (inputs) => {
        if (form.formState.isValid === true) {
            const { password, username } = inputs;

            await changePasswordMutation.mutateAsync({
                body: {
                    username,
                    password,
                    uuid,
                },
                location,
            });
        }
    };

    const form = useForm<VmChangePasswordInputs>({
        disabled: changePasswordMutation.isPending,

        mode: "onBlur",
        reValidateMode: "onBlur",

        defaultValues: {
            username,
            password: "",
            password_confirm: "",
        },
    });

    return (
        <WModalContent
            title="Change Password"
            label="Change"
            isActionDisabled={status === "stopped" || changePasswordMutation.isPending || !form.formState.isValid}
            modalAction={async () => {
                await onSubmit(form.getValues());
            }}
            footerNotice={
                status === "stopped" ? "Password change is possible only when machine is running." : undefined
            }
        >
            <ModalHeaderBlock vm={vm} showSize={false}>
                <WButton
                    isDisabled={status === "running"}
                    icon="jp-icon-run"
                    variant="ghost"
                    color="primary"
                    action={async () => {
                        await startMutation.mutateAsync({ uuid, location });
                    }}
                >
                    {status === "running" ? "Running" : "Start"}
                </WButton>
            </ModalHeaderBlock>

            <p className="pt-2">Password can be changed for any existing user account.</p>

            <WHookForm form={form} onSubmit={onSubmit}>
                <ParamsTextField
                    params={usernameParams}
                    autoComplete="username"
                    label="Username"
                    // TODO: Constraint description should come from the VM parameters
                    description="The user account for which password will be set."
                    isReadOnly={isWindows}
                    defaultValue={username}
                />

                <ParamsTextField
                    params={passwordParams}
                    type="password"
                    autoComplete="new-password"
                    label="Password"
                    // TODO: These should come partially from the VM parameters
                    description="New password for the user. Must contain at least one lowercase and one uppercase ASCII letter (a-z, A-Z) and at least one digit (0-9). Minimum length is 8 characters."
                />

                <ParamsTextField
                    params={{ ...passwordParams, parameter: "password_confirm" }}
                    type="password"
                    autoComplete="new-password"
                    label="Confirm password"
                    registerOptions={{
                        deps: ["password"],
                        validate: (value, { password }) => {
                            return value === password || "Passwords do not match";
                        },
                    }}
                    description="The re-typed password, to ensure that it has been entered correctly."
                />
            </WHookForm>
        </WModalContent>
    );
}

export function ChangePasswordModal({ vm }: { vm: VirtualMachineLoc }) {
    return (
        <WModal button={<WModalButton label="Change Password" icon="jp-lock-icon" />}>
            <ChangePasswordModalContent vm={vm} />
        </WModal>
    );
}
